@import url(https://fonts.googleapis.com/css2?family=Space+Mono&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bordered{
  border:1px gray solid;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;}
.login-wrapper{
 height: auto;
 padding: 20px;
 }
 .login-logo{
   max-height: 100px;
 }
.msc-nav{
  background-color: #ffffff;
padding-top: 15px;
padding-bottom: 15px;
padding-right: 0;
padding-left: 0;
border-bottom: #5e616c 1px solid;
border-top: #5e616c 1px solid;
}
.navbar-toggler{
  outline: 0;
  border: none;
  color: #121521;
  
}
.nav-item .nav-link{

text-transform: uppercase;

  
}
.navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}
.msc-nav .navbar .others-options .option-item {
  color: #121521;
  display: inline-block;
  position: relative;
  line-height: normal;
  line-height: initial;
  margin-left: 15px;
  top: 4px;
}
.msc-nav .navbar{
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
 
}

.msc-nav .btn-primary i {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 33px;
  line-height: 33px;
  color: #ffffff;
  border-radius: 50%;
  background-color: #89b8f5;
  transition: 0.5s;
}



nav{ background-color: #ffffff;}

.navbar-toggler-icon{
  color: #212529;
}
.nav-link{
  font-size: 15px;
  font-weight:bolder;
line-height: 1.5;
color: #212529;
}
.nav-item a.active {
  color: #0a58ca;

}
.navbar-brand img{
  max-height: 65px;
  
}
.section-title span {
  display: block;
  margin-bottom: 5px;
  color: #0a58ca;
  font-size: 17px;
}
.section-title h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
  font-family: 'Bebas Neue', cursive;
}
.section-title p {
  max-width: 520px;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.section-title{
  margin-bottom:100px;
  margin-top: 100px;
}

.main-services-box {
 
  background-color: #ffffff;
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.2);
  padding: 25px 20px;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
}

.main-services-box .icon {
  margin-bottom: 20px;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 100%;
  background-color: #0a58ca;;
  color: #ffffff;
  font-size: 25px;
  transition: 0.5s;
}

.main-services-box h3 {
  font-size: 19px;
  font-weight: 700;

}
.main-services-box h3 a {
  color: #121521;
}

.main-services-box p {
  line-height: 1.7;
  transition: 0.5s;
  margin-top: 10px;
  margin-bottom: 0;
  color: #7d7d7d;
line-height: 1.8;
margin-bottom: 12px;
font-size: 14.5px;
}
.main-services-box:hover {
  transform: translateY(-8px);

background-color: #0a58ca;
transition: 0.5s;
}
.main-services-box:hover p{
  color: #ffffff;
}
.main-services-box:hover h3 a{
  color: #ffffff;
}

.main-services-box:hover .icon {
  background-color: #0a58ca;
  color: #ffffff;
}
.about-area {
  position: relative;
  padding-top:100px ;
  z-index: 1;
}
.about-image {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/msc-about.4d591ab4.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-image img {
  display: none;
}

.about-content {
  max-width: 555px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 50px;

 
}
.about-content span {
  display: block;
  margin-bottom: 5px;
  color: #0a58ca;
  font-size: 17px;
  
}
.about-content h2 {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 40px;
  font-weight: 600;
  font-family: 'Bebas Neue', cursive;
}

.about-content p {
  margin-top: 10px;
  margin-bottom: 0;
}
.about-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}
.about-content ul li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
}
.about-content .btn {
  margin-top: 30px;
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.2);
}
.btn-primary {
  background: #0a58ca;
  color: #ffffff;
}
.btn {
  border: none;
  line-height: normal;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 500;
}
.about-content .btn-primary i {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 33px;
  line-height: 33px;
  color: #ffffff;
  border-radius: 50%;
  background-color:#89b8f5 ;
  transition: 0.5s;
}


.about-content ul li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  background-color:#89b8f5;
  color:#0a58ca;
  transition: 0.5s;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}
.btn-primary:hover, .btn-primary:focus {
  box-shadow: unset !important;
  background: #121521 !important;
  color: #ffffff !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}


.banner img {
  width: 100%;
}
.banner
  {
  
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    padding: 0;
    
}
.text-mask {
  background-color: rgba(0,0,0, 0.3);
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
  
}

.text-mask h1 {
  background-color: rgba(0,0,0, 0.2);
  font-size: 60px;

  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-mask p {
  background-color: rgba(0,0,0, 0.2);
  width: 50%;
  position: absolute;
  top: 65%;
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.our-mission-area {
  position: relative;
  z-index: 1;
}

.our-mission-content {
  max-width: 640px;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 50px;
}
.our-mission-content .sub-title {
  display: block;
  margin-bottom: 5px;
  color:#0a58ca;
  font-size: 17px;
}

.our-mission-content h2 {
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 40px;
  font-weight: 600;
  font-family: 'Bebas Neue', cursive;
}
.our-mission-content p {
  margin-top: 10px;
  margin-bottom: 0;
  color: #7d7d7d;
line-height: 1.8;
margin-bottom: 12px;
font-size: 14.5px;
}
.our-mission-content ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 0;
  margin-top: 0;
}
.our-mission-content ul li {
  flex: 0 0 50%;
  max-width: 50%;
  color: #7d7d7d;
  font-size: 14.5px;
  line-height: 1.7;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25px;
}

.our-mission-content ul li .icon {
  margin-bottom: 13px;
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.5);
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: #0a58ca;
  border: 1px #0b5ed7 solid;
  
  font-size: 16px;
  transition: 0.5s;
}

.our-mission-content ul li span {
  display: block;
  color: #121521;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 17px;
}
.our-mission-content ul li .icon {
  margin-bottom: 13px;
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.3);
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: #0a58ca;
  font-size: 16px;
  transition: 0.5s;
}








.our-mission-image {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/mission-img.b908aa68.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.our-mission-image img {
  display: none;
}

























.footer-area {
  background-color: #121521;
  padding-top: 80px;
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .logo a {
  display: inline-block;
}
a {
  transition: 0.5s;
  outline: 0 !important;
  text-decoration: none;
  color: #121521;
}

.single-footer-widget .logo p {
  color: #cecece;
  margin-top: 15px;
  margin-bottom: 0;
}
p:last-child {
  margin-bottom: 0;
}

.single-footer-widget .social {
  padding-left: 0;
  list-style-type: none;
  margin-top: 18px;
  margin-bottom: 0;
}

.single-footer-widget .social li:first-child {
  margin-left: 0;
}
.single-footer-widget .social li {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.single-footer-widget .social li {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
.single-footer-widget .social li:last-child {
  margin-right: 0;
}
.single-footer-widget .social li {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget h3 {
  margin-bottom: 30px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
}
.single-footer-widget .departments-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol, ul {
  padding-left: 2rem;
}
.single-footer-widget .departments-list li {
  margin-bottom: 10px;
  color: #cecece;
  font-size: 14.5px;
  font-family: "Rubik", sans-serif;
}
.single-footer-widget .links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .links-list li {
  margin-bottom: 10px;
  color: #cecece;
  font-size: 14.5px;
  font-family: "Rubik", sans-serif;

}
.single-footer-widget .departments-list li a {
  display: inline-block;
  color: #cecece;
}

.single-footer-widget .links-list li a {
  display: inline-block;
  color: #cecece;
}
.single-footer-widget .departments-list li a:hover {
  color: #19ce67;
}
a {
  transition: 0.5s;
  outline: 0 !important;
  text-decoration: none;
  color: #121521;
}
.single-footer-widget .links-list li a:hover {
  color: #19ce67;
}
.single-footer-widget .opening-hours {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .opening-hours li {
  color: #cecece;
  border-bottom: 1px solid #393c49;
  overflow: hidden;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}
.copyright-area {
  text-align: center;
  border-top: 1px solid #272a35;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copyright-area p {
  line-height: normal;
  line-height: initial;
  color: #ffffff;
  margin-bottom: 0;
}
.copyright-area p a:hover {
  color: #19ce67;
}
.copyright-area p a {
  color: #ffffff;
  display: inline-block;
}

.go-top.active {
  top: 98%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top {
  position: fixed;
  cursor: pointer;
  top: 50%;
  right: 15px;
  background: #d2f5e1;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: .9s;
  color: #19ce67;
  border-radius: 3px;
  font-size: 20px;
}


.dash-row{
  min-height: calc(100vh - 106px);
}
.sidebar {
  color: white;
  text-decoration: none;
}
.sidebar .btn{
  padding: 10px;
  border-bottom: 1px #393c49 solid;
  border-radius: 0;
}
.btn-group-vertical {
  width: 100% !important;
}
.sidebar .btn:hover{
  background-color: #393c49;
}
.sprator{
  min-height: 10px;
}

.doctor-area{
 
}


.doctor-card{
  text-align: center;
margin-bottom: 30px;
background-color: #fff;
box-shadow: 0 10px 55px 5px rgba(137,173,255,.2);
padding: 25px;
border-radius: 5px;
transition: .5s;
border: none;
}
.doctor-card h5{
    transition: .5s;
    text-transform: capitalize;
    outline: 0 !important;
    text-decoration: none;
    color: #004080;
}
.doctor-card p{
  display: block;
  color: #19ce67;
  font-size: 14.5px;
  margin-top: 5px;
}
.doctor-card span{
  display: block;
  color: #004080;
  font-size: 16px;
  
}
.photo-card{
  box-shadow: 0 10px 55px 5px rgba(137,173,255,.3);
  border-radius: 4px;
  border: 2px solid #212529;
}
.photo-card button {
  outline: 0 !important;
  box-shadow: none !important
  
}
.photo-link{
  background-color: #0b5ed7;
  color: white;
}
.photo-link i{
  position: absolute;
right: 7px;
top: 50%;
transform: translateY(-50%);
text-align: center;
display: inline-block;
height: 33px;
width: 33px;
line-height: 33px;
color: #ffffff;
border-radius: 50%;
background-color: #89b8f5;
transition: 0.5s;
}

.doctor-head h5{
  transition: .5s;
  text-transform: capitalize;
  outline: 0 !important;
  text-decoration: none;
  color: #004080;
}
.doctor-head p{
display: block;
color: #19ce67;
font-size: 14.5px;
margin-top: 5px;
}
.doctor-head span{
display: block;
color: #004080;
font-size: 16px;

}
.treatments h5{
 color: #121521;
}


.editorS{white-space: pre-line;}
.text-about{
  font-size: 18px;
}

.page-title
  {
    background-image: url(/static/media/page-title-bg.e0af219e.jpg);
    min-height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    padding: 0;
}
.page-title .text-mak {
  background-color: rgba(0,0,0, 0.2);
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
 padding-top: 90px;
  min-height: 350px;
}
.page-title .text-mak h1 {
  font-size: 50px;
 background-color: rgba(0,0,0, 0.2);
}
.header-logo{
  width: 25%;
}
.btn-border{
  border: 1px solid black ;
  border-radius: 4px;
}
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
   .text-mask h1{
     font-size: 28px;
   }
   .text-mask p {
     font-size: 16px;
    margin-top: 20px;
     text-align: center;
     width: 100%;
   }
   .our-mission-content{
     padding-left: 50px;
   }
   .header-logo{
     width: 75%;
   }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .text-mask h1{
    font-size: 28px;
  }
  .text-mask p {
    font-size: 16px;
   
    text-align: center;
   margin-top: 20px;
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  .text-mask h1{
    font-size: 28px;
  }
  .text-mask p {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
   
  }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  .text-mask h1{
    font-size: 32px;
  }
  .text-mask p {
    font-size: 16px;
    margin-top: 30px;
    text-align: center;
    
  }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {} 

